import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Box, Flex } from 'rebass/styled-components';
import TextTruncate from 'react-text-truncate';
import htmlToText from 'html-to-text';
import PostCard from './PostCard';

const FullHeightCard = styled(PostCard)`
  min-height: 100%;
`;

const Column = styled(Box)`
  margin: 0 0 2rem;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    margin: 0 1% 2rem;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
`;

export default class IndexPage extends React.Component {
  render() {
    const { posts } = this.props;

    return (
      <Flex
        alignItems={['stretch']}
        flexDirection={['column', 'row']}
        flexWrap="wrap"
      >
        {posts.map(({ node: post }) => (
          <Column key={post.id} width={[1, 1 / 2, '32%']} mb={['2rem', '0']}>
            <FullHeightCard
              style={{ height: '100%' }}
              image={
                post
                && post.featured_media
                && post.featured_media.localFile
                && post.featured_media.localFile.childImageSharp
                && post.featured_media.localFile.childImageSharp.fluid
                && (
                  <Img
                    fluid={post.featured_media.localFile.childImageSharp.fluid}
                    alt={post.featured_media.alt_text}
                  />
                )
              }
              title={post.title}
              link={`/${post.slug}/`}
              tags={post.tags}
              text={
                post.excerpt && (
                  <TextTruncate
                    text={htmlToText.fromString(
                      post.excerpt.replace(/<p class="link-more.*/, ''),
                    )}
                    line={3}
                  />
                )
              }
              actionButtonLabel="Číst dále →"
            />
          </Column>
        ))}
      </Flex>
    );
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
};

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    featured_media {
      alt_text
      localFile {
        childImageSharp {
          fluid(maxWidth: 640, maxHeight: 360) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    tags {
      id
      name
      slug
      count
    }
  }
`;
